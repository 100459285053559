


































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import DynamicForm from '@/components/Forms/DynamicForm.vue';
import AbSubFormModel from '@/components/Models/AbSubFormModel';
import DynamicSection from '@/components/Forms/DynamicSection.vue';
import {uuid} from 'vue-uuid';
import FormMixin from '@/components/Mixin/FormMixin';
import AbModel from "@/components/Models/AbModel";

@Component({
  components: {DynamicSection}
})
export default class DynamicSubForm extends Mixins(FormMixin, DynamicForm) {
  @Prop(Object) subFormFieldData;
  @Prop(Object) subFormFieldInfo;

  model: AbSubFormModel = new AbSubFormModel();

  saveToModel(dataToSave) {
    const model = new AbModel();
    if (!dataToSave.ID) {
      dataToSave.ID = uuid.v1();
      model.setCreated(dataToSave);
    } else {
      model.setUpdated(dataToSave);
    }

    this.$emit("saveSubformData", dataToSave);
  }

  async getModelData() {
    return this.subFormFieldData;
  }

  mounted() {
    //console.log(this.dynFields);
    //console.log(this.formData.data);
    //console.log(this.subFormField);
  }

}
